import React from 'react';
import withInstagramFeed from 'origen-react-instagram-feed';
import compose from 'recompose/compose';

import InstaStyles from './Instagrid.module.scss';

const InstaGrid = ({ classes, media, account, status }) => {
	return (
		<div className={classes}>
			{media &&
				status === 'completed' &&
				media.map(({ displayImage, id, postLink, accessibilityCaption }) => {
					return (
						<div key={id} className={InstaStyles.instaPost}>
							<a href={postLink || `https://www.instagram.com/${account}`}>
								<img src={displayImage} alt={accessibilityCaption || 'insta picture'} />
							</a>
						</div>
					);
				})}
		</div>
	);
};

export default compose(withInstagramFeed)(InstaGrid);
