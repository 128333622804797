import React from 'react';
import InstaGrid from '@molecules/Instagrid/Instagrid';

import Container from '@atoms/Container/Container';
import Cta from '@atoms/Cta/Cta';
import Icon from '@atoms/Icon/Icon';

import InstaStyles from './Instagram.module.scss';

const Instagram = ({ accountName }) => {
	return (
		<div className={InstaStyles.instagramWrapper}>
			<Container className={InstaStyles.instagramWrapper}>
				<h2 className="is-2">See What&apos;s Happening on Instagram</h2>
				<InstaGrid account={accountName} classes={InstaStyles.instagram} numberOfMediaElements={5} />
				<Cta url={`https://www.instagram.com/${accountName}`} theme="dark">
					<Icon font="fab" name="fa-instagram" /> Follow us on Instagram
				</Cta>
			</Container>
		</div>
	);
};

export default Instagram;
