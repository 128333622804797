import React from 'react';

import { parseClassNames } from '@utilities/parseClassNames';

import PanelStyles from './Panel.module.scss';

const Panel = ({ children, orientation = 'left', className = '', theme = 'white' }) => {
	const classNames = parseClassNames([
		PanelStyles.panel,
		className,
		PanelStyles[`orientation_${orientation}`],
		PanelStyles[`panel_theme_${theme}`],
		'yh-panel',
	]);

	return <div className={classNames}>{children}</div>;
};

export default Panel;
