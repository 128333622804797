import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Hero from '@organisms/Hero/Hero';

import Layout from '@organisms/Layout/Layout';
import Container from '@atoms/Container/Container';
import CopyBlock from '@atoms/CopyBlock/CopyBlock';
import Cta from '@atoms/Cta/Cta';
import Panel from '@molecules/Panel/Panel';
import ContactForm from '@organisms/ContactForm/ContactForm';
import Instagram from '@organisms/Instagram/Instagram';

import Mayo from '../../img/home/community.jpg';
import BehindTheScenes from '../../img/home/behind-the-scenes.jpg';
import PreserveCulture from '../../img/home/preserving-our-culture.jpg';

import heroDesktop from '../../img/home/hero.jpg';
import heroXlarge from '../../img/home/hero-xlarge.jpg';
import heroTablet from '../../img/home/hero-tablet.jpg';

import styles from './index-page.module.scss';

const images = {
	xlarge: heroXlarge,
	desktop: heroDesktop,
	tablet: heroTablet,
};
export const IndexPageTemplate = ({
	title,
	subTitle,
	video,
	videoTitle,
	videoDescription,
	videoDescriptionTrans,
	fullVideoUrl,
}) => (
	<>
		<Hero
			images={images}
			title={title}
			subTitle={subTitle}
			video={video}
			videoTitle={videoTitle}
			videoDescription={videoDescription}
			videoDescriptionTrans={videoDescriptionTrans}
			fullVideoUrl={fullVideoUrl}
		/>
		<Panel orientation="right" theme="yellow">
			<Container>
				<div className="inner-container">
					<CopyBlock>
						<h2 className="is-2">The Mayo Community</h2>
						<hr className="title-border" />
						<p>
							The First Nation of Na-Cho Nyak Dun represent the most northerly community of Northern Tutchone speakers.
							Learn more about the extensive history and beautiful scenery.
						</p>
						<p className="italic">Gen dän nachone dän nyenam dän ka kʼé yehonii łatade.</p>
						<Cta url="/mayo-community" theme="light">
							Watch
						</Cta>
					</CopyBlock>
				</div>
				<img src={Mayo} className={`is-hidden-mobile ${styles.panel__img}`} alt="" />
			</Container>
		</Panel>
		<Panel orientation="left" theme="white">
			<Container>
				<div className="inner-container">
					<CopyBlock>
						<h2 className="is-2">Preserving our Culture</h2>
						<hr className="title-border" />
						<p>Learn about the communities and cultures explored in Dän K’eht’e / Yukon Harvest.</p>
						<p className="italic">
							Gęgeh dän gänkeyets nethän tławke eteh rade huchonii etset hejuto hodän dän kʼéh iyets hóhun yetsʼeho
							niiniithän.
						</p>
						<Cta url="/preserving-our-culture" theme="light">
							Watch
						</Cta>
					</CopyBlock>
				</div>
				<img src={PreserveCulture} className={`is-hidden-mobile ${styles.panel__img}`} alt="" />
			</Container>
		</Panel>

		<Panel orientation="right" theme="yellow">
			<Container>
				<div className="inner-container">
					<CopyBlock>
						<h2 className="is-2">Behind the Scenes</h2>
						<hr className="title-border" />
						<p>Go beyond the series with Behind the Scenes footage from Dän K’eht’e / Yukon Harvest.</p>
						<p className="italic">
							Jen taǫ tsedan yedin nedii. Dän sóthän tawte enaʼra dayii tlawche ene ráde etoniiye yetʼeh hádän seyetii.
							Netson yetsii tän gän oche kʼawgän hokʼe hojun däniye honii uti jąnkeh.
						</p>
						<Cta url="/behind-the-scenes" theme="light">
							Watch
						</Cta>
					</CopyBlock>
				</div>
				<img src={BehindTheScenes} className={`is-hidden-mobile ${styles.panel__img}`} alt="" />
			</Container>
		</Panel>

		<Instagram accountName="yukonharvest" />

		<ContactForm />
	</>
);

IndexPageTemplate.propTypes = {};

const IndexPage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout>
			<IndexPageTemplate
				title={frontmatter.title}
				subTitle={frontmatter.subTitle}
				video={frontmatter.video}
				videoTitle={frontmatter.videoTitle}
				videoDescription={frontmatter.videoDescription}
				videoDescriptionTrans={frontmatter.videoDescriptionTrans}
				fullVideoUrl={frontmatter.fullVideoUrl}
			/>
		</Layout>
	);
};

IndexPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}).isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
	query IndexPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
			frontmatter {
				title
				subTitle
				video
				videoTitle
				videoDescription
				videoDescriptionTrans
				fullVideoUrl
			}
		}
	}
`;
